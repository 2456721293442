import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import swal from 'sweetalert';
import { FaEyeSlash } from 'react-icons/fa';

export default class CategoryTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subcategories: []
        };
        this.deleteCategory = this.deleteCategory.bind(this);
    }

    componentDidMount() {
        this.getSubCategories(this.props.obj._id);
    }

    deleteCategory() {
        axios.delete('/categories/delete-category/' + this.props.obj._id)
            .then((res) => {
                swal("Berhasil!", "Kategori telah dihapus!", "success").then((value) => {
                    window.location.reload();
                });
            }).catch((error) => {
                console.log(error)
            })
    }

    getSubCategories(id) {
        axios.get(`/categories/subcategories/${id}`)
            .then(res => {
                this.setState({
                    subcategories: res.data
                });

            })
            .catch((error) => {
                console.log(error);
            })
    }

    subcategory() {
        if (this.state.subcategories.length > 0) {
            return this.state.subcategories.map((sub, i) => {
                return (
                    <li key={i}>{sub.name} {sub.status !== 1 && <FaEyeSlash />}  <Badge pill bg="info">
                        {sub.score}%
                    </Badge></li>
                )
            })
        }

        return (
            <span></span>
        )

    }

    render() {
        return (
            <tr>
                <td>{this.props.obj.name} {this.props.obj.status !== 1 && <FaEyeSlash />}
                    <ol>
                        {this.subcategory()}
                    </ol>
                </td>
                <td>
                    <Link className="edit-link" to={"/edit-category/" + this.props.obj._id}>
                        Edit
                    </Link>
                    <Button onClick={this.deleteCategory} size="sm" variant="danger">Delete</Button>
                </td>
            </tr>
        );
    }
}