import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

export default class EditPeriod extends Component {

    constructor(props) {
        super(props)

        this.onChangePeriodName = this.onChangePeriodName.bind(this);
        this.onChangePeriodStatus = this.onChangePeriodStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // State
        this.state = {
            name: '',
            status: ''
        }
    }

    componentDidMount() {
        axios.get('/periods/edit-period/' + this.props.match.params.id)
            .then(res => {
                this.setState({
                    name: res.data.name,
                    status: res.data.status
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onChangePeriodName(e) {
        this.setState({ name: e.target.value })
    }

    onChangePeriodStatus(e) {
        this.setState({ status: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        const periodObject = {
            name: this.state.name,
            status: this.state.status
        };

        axios.put('/periods/update-period/' + this.props.match.params.id, periodObject)
            .then((res) => {
                console.log(res.data)
                this.props.history.push('/period-list')
                console.log('Period successfully updated')
            }).catch((error) => {
                console.log(error)
            })

        // Redirect to Period List 

    }


    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangePeriodName} />
                </Form.Group>

                <Form.Group controlId="status" className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Pilih Status" value={this.state.status} onChange={this.onChangePeriodStatus}>
                        <option value="1">Aktif</option>
                        <option value="0">Draft</option>
                    </Form.Select>
                </Form.Group>

                <Button variant="success" size="sm" block="block" type="submit" className="w-100">
                    Update Periode
                </Button>
            </Form>
        </div>);
    }
}