import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ScoreTableRow from './ScoreTableRow';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { FaChevronRight, FaStar, FaCheckCircle } from 'react-icons/fa';


class ScoreList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            loading: false,
            students: [],
            periods: [],
            selectedStudent: '',
            selectedStudentId: '',
            selectedPeriod: '',
            selectedPeriodId: '',
            scores: {},
            completion: {},
            sentScore: [],
            childKey: Date.now()
        };
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };



    componentDidMount() {
        this.setState({
            loading: true
        });
        this.getSentScore();
        const { cookies } = this.props;
        var token = cookies.get('token');
        axios.get('/students/')
            .then(res => {
                var st = res.data;
                const filtered = st.filter(b => b._id !== token);

                this.setState({
                    students: filtered

                });
            })
            .catch((error) => {
                console.log(error);
            })
        axios.get('/periods/')
            .then(res => {
                this.setState({
                    periods: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
        axios.get('/categories/')
            .then(res => {
                this.setState({
                    categories: res.data
                });

            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    loading: false
                });
            })
    }


    getScoreData = (val) => {
        var newScores = { ...this.state.scores, ...val };
        this.setState({
            scores: newScores
        })

    }

    getSentScore = () => {
        const { cookies } = this.props;
        var token = cookies.get('token');
        var period = cookies.get('period_id');
        var periodName = cookies.get('period_name');
        this.setState({
            selectedPeriodId: period,
            selectedPeriod: periodName
        });
        axios.post('/scores/sent-score', {
            "sender": token,
            "period": period
        }).then(res => {
            this.setState({
                sentScore: res.data
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    getCompletion = (val) => {
        // not used anymore, as selection defaulted to "2" hence always complete
        var newComp = { ...this.state.completion, ...val };
        this.setState({
            completion: newComp
        })


    }

    onSubmit = (e) => {
        e.preventDefault();

        // for (const key in this.state.completion) {
        //     if (this.state.completion[key] !== 1) {
        //         toast.error(`Lengkapi semua penilaian`);
        //         return;
        //     };
        // }

        const { cookies } = this.props;
        var token = cookies.get('token');
        var name = cookies.get('name');
        var period_name = cookies.get('period_name');
        var period_id = cookies.get('period_id');

        var payload = {
            "creator_id": token,
            "creator_name": name,
            "receipient_id": this.state.selectedStudentId,
            "receipient_name": this.state.selectedStudent,
            "score": this.state.scores,
            "period_name": period_name,
            "period_id": period_id
        }
        axios.post('/scores/create-score', payload)
            .then(res => {
                if (res.data.success === false && res.data.type === "exist") {
                    toast.error(`Anda sudah menilai ${this.state.selectedStudent} di ${this.state.selectedPeriod}`);
                    return;
                }

                if (res.data.success === false && res.data.type === "self") {
                    toast.error(`Tidak dapat menilai diri sendiri`);
                    return;
                }
                var newStudent = this.state.students.filter(s => {
                    return s._id !== this.state.selectedStudentId;
                });
                this.setState({
                    students: newStudent,
                    childKey: new Date()
                });
                toast.success(`Score untuk ${this.state.selectedStudent} tersimpan`, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                });
                // this.setState({
                //     childKey: Date.now()
                // })
                this.getSentScore();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    DataTable() {
        return this.state.categories.map((res, i) => {
            return <ScoreTableRow obj={res} key={i} id={this.state.childKey} sendData={this.getScoreData} sendCompletion={this.getCompletion} />;
        });
    }

    StudentList() {
        return this.state.students.map(std => {
            return (
                <option key={std._id} value={std._id}>{std.name}</option>
            )
        })
    }

    PeriodList() {
        return this.state.periods.map(per => {
            return (
                <option key={per._id} value={per._id}>{per.name}</option>
            )
        })
    }

    deleteScore = async (id, name) => {
        var result = window.confirm(`Hapus penilaian ${name}?`);
        if (!result) {
            return;
        }
        try {
            await axios.delete(`/scores/delete-score/${id}`);
            toast.success(`Penilaian dihapus`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            this.getSentScore();
        } catch (error) {
            console.log(error);
            toast.error(`Gagal menghapus`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        }

    }

    categoryScoreList(data) {
        var keys = Object.keys(data);
        return keys.map((k, i) => {
            return (
                <div key={i}>
                    <div><strong>{k}</strong></div>
                    {this.subScoreList(data[k])}
                </div>
            )
        })
    }

    star(val) {
        return [...Array(parseInt(val))].map((k, i) => {
            return (
                <FaStar key={i} color="orange" />
            )
        })
    }

    subScoreList(data) {
        var keys = Object.keys(data);
        return keys.map((k, i) => {
            return (
                <div key={i}><FaChevronRight style={{ marginLeft: "10px", color: "#0dcaf0" }} /> {k} {this.star(data[k])}</div>
            )
        })
    }

    duplicatedScore() {
        var arr = [];
        var duplicated = [];
        this.state.sentScore.forEach(el => {
            if (arr.includes(el.receipient_name)) {
                duplicated.push(el.receipient_name);
            } else {
                arr.push(el.receipient_name);
            }

        });

        return duplicated.map((d, i) => {
            return (
                <li key={i}>
                    {d}
                </li>

            )
        })
    }

    SentScoreList() {
        return this.state.sentScore.map((per, i) => {
            return (
                <Accordion className="fadeIn" key={per._id}>
                    <Accordion.Item eventKey={per._id}>
                        <Accordion.Header><strong>{i + 1}. {per.receipient_name.toUpperCase()} </strong> <FaCheckCircle className="mx-2" color="green" />
                            <div onClick={() => this.deleteScore(per._id, per.receipient_name.toUpperCase())} style={{ fontSize: "8px" }} className="btn btn-sm btn-danger">HAPUS</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {this.categoryScoreList(per.score)}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )
        })
    }

    handleClick = () => {
        this.props.history.push("/create-subcategory");
    }

    handleChange = (e) => {
        if (e.target.name === "user") {
            this.setState({
                selectedStudent: e.target.options[e.target.selectedIndex].text,
                selectedStudentId: e.target.value,
                childKey: new Date()
            })
        }

        if (e.target.name === "period") {
            const { cookies } = this.props;
            cookies.set('period_name', e.target.options[e.target.selectedIndex].text);
            cookies.set('period_id', e.target.value);
            this.setState({
                selectedPeriod: e.target.options[e.target.selectedIndex].text,
                selectedPeriodId: e.target.value,
            })

            if (e.target.value !== "") {
                this.getSentScore();
            }
        }

    }


    render() {
        return (<div className="row fadeIn">
            <div className="d-flex justify-content-center">{this.state.loading && <Spinner animation="border" variant="info" />}</div>

            <Form onSubmit={this.onSubmit} className="col-md-6 mb-2">
                <Form.Group controlId="karyawan">
                    <Form.Select name="user" required aria-label="Pilih Karyawan" onChange={this.handleChange}>
                        <option value="">Pilih Pegawai</option>
                        {this.StudentList()}
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId="period" className="my-2">
                    <Form.Select required name="period" value={this.state.selectedPeriodId} aria-label="Pilih Periode" onChange={this.handleChange}>
                        <option value="">Pilih Periode</option>
                        {this.PeriodList()}
                    </Form.Select>
                </Form.Group>



                {!this.state.loading && <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className="text-success text-sm"><Alert variant="warning" className="p-1 m-0">
                                <small>1: Perlu diperbaiki<br />2: Sesuai harapan <br />3: Lebih dari yg diharapkan</small>
                            </Alert></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.DataTable()}
                    </tbody>
                </Table>}

                <div className="d-flex justify-content-end mb-2"><Button type="submit" size="sm" variant="success">Kirim Penilaian: {this.state.selectedStudent}</Button></div>

            </Form>

            <div className="col-md-6">
                <Alert variant="info">
                    Pegawai yang telah anda nilai untuk periode: {this.state.selectedPeriod}
                </Alert>
                {this.duplicatedScore().length > 0 &&
                    <div>
                        <span className="text-danger text-bold">Terdapat duplikasi penilaian:</span>
                        <ul>
                            {this.duplicatedScore()}
                        </ul></div>}
                {this.duplicatedScore().length === 0 &&
                    <div className="mb-3"><span className="text-success text-bold">Tidak ada duplikasi</span></div>}
                {this.SentScoreList()}</div>

        </div>);
    }
}

export default withCookies(ScoreList);