import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

export default class EditCategory extends Component {

    constructor(props) {
        super(props)

        this.onChangeCategoryName = this.onChangeCategoryName.bind(this);
        this.onChangeCategoryStatus = this.onChangeCategoryStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // State
        this.state = {
            name: '',
            status: ''
        }
    }

    componentDidMount() {
        axios.get('/categories/edit-category/' + this.props.match.params.id)
            .then(res => {
                this.setState({
                    name: res.data.name,
                    status: res.data.status
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onChangeCategoryName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeCategoryStatus(e) {
        this.setState({ status: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        const categoryObject = {
            name: this.state.name,
            status: this.state.status
        };

        axios.put('/categories/update-category/' + this.props.match.params.id, categoryObject)
            .then((res) => {
                console.log(res.data)
                this.props.history.push('/category-list')
                console.log('Category successfully updated')
            }).catch((error) => {
                console.log(error)
            })

        // Redirect to Category List 

    }


    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangeCategoryName} />
                </Form.Group>

                {/* <Form.Group controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Pilih Status" value={this.state.status} onChange={this.onChangeCategoryStatus}>
                        <option value="1">Aktif</option>
                        <option value="0">Draft</option>
                    </Form.Select>
                </Form.Group> */}

                <Button className="mt-3 w-100" variant="success" size="sm" block="block" type="submit">
                    Update Kategori
                </Button>
            </Form>
        </div>);
    }
}