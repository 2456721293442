import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import AllScore from './all-score.component';
import Button from 'react-bootstrap/Button';
import { FaPrint } from 'react-icons/fa';
import axios from 'axios';


function Report() {

    const [period, setPeriod] = useState([]);
    const [students, setStudents] = useState([]);
    const [studentsOri, setStudentsOri] = useState([]);
    const [periodId, setPeriodId] = useState("");
    const [detail, setDetail] = useState(false);
    const [studentId, setStudentId] = useState("");
    const [state, setState] = useState(new Date());
    const printRef = useRef();



    useEffect(() => {
        fetchPeriod();
    }, []);

    function fetchPeriod() {
        axios.get('/periods/')
            .then(res => {
                setPeriod(res.data);
            }).catch((error) => {
                console.log(error);
            })
        axios.get('/students/')
            .then(res => {
                var st = res.data;
                //const filtered = st.filter(a => a.role === 1);
                setStudents(res.data);
                setStudentsOri(res.data);
            })
            .catch((error) => {
                console.log(error);
            })

    }

    function changeDetail() {
        setDetail(!detail);
        setState(new Date());
    }

    function handleChange(e) {

        if (e.target.name === "period") {
            setPeriodId(e.target.value);
        }

        if (e.target.name === "pegawai") {
            setStudentId(e.target.value);
            if (e.target.value !== '') {
                var filteredStudent = studentsOri.filter(s => s._id === e.target.value);
                setStudents(filteredStudent);
            } else {
                setStudents([...studentsOri]);
            }
            // console.log(students);
            // console.log(studentId);



        }

        if (periodId !== "") {
            setState(new Date());
        }
    }

    function PeriodList() {
        return period.map(per => {
            return (
                <option key={per._id} value={per._id}>{per.name}</option>
            )
        })
    }

    function StudentList() {
        return studentsOri.map(st => {
            return (
                <option key={st._id} value={st._id}>{st.name}</option>
            )
        })
    }

    function StudentTable() {
        return students.map((s, i) => {
            return (
                <AllScore key={i} detail={detail} image={"/user/image/" + s.image} name={s.name} id={s._id} period={periodId} />
            )
        });
    }

    return (
        <div>
            <div className='d-flex justify-content-end align-items-center'>
                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Detail"
                    className="noPrint mx-2"
                    onChange={changeDetail}
                />
                <Button className="noPrint" onClick={() => window.print()} ><FaPrint /> Print</Button>
            </div>
            <Form.Group controlId="karyawan" className='mt-2'>
                <Form.Select value={periodId} name="period" required aria-label="Pilih Karyawan" onChange={handleChange}>
                    <option value="">Pilih Periode</option>
                    {PeriodList()}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="pegawai" className='mt-2 noPrint'>
                <Form.Select key={studentId} value={studentId} name="pegawai" required aria-label="Pilih Pegawai" onChange={handleChange}>
                    <option value="">Semua Pegawai</option>
                    {StudentList()}
                </Form.Select>
            </Form.Group>
            {periodId && <div key={state}>{StudentTable()}</div>}
        </div>
    )
}

export default Report
