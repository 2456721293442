import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function AllScore(props) {

    const [score, setScore] = useState([]);
    const [students, setStudents] = useState([]);
    const [globalScore, setGlobalScore] = useState({});
    const [subCategories, setSubCategories] = useState({});
    const [cookies, setCookie] = useCookies(['token', 'period_id']);

    useEffect(() => {
        if (props.period !== '') {
            fetchSub();
            fetchScore();
        }

    }, []);


    function fetchSub() {
        axios.get('/subcategories/')
            .then(res => {
                let data = res.data;
                let dataObj = {};
                data.forEach(el => {
                    dataObj[el.name] = el.score;
                });
                setSubCategories(dataObj);
            }).catch((error) => {
                console.log(error);
            })

        axios.get('/students/')
            .then(res => {
                var st = res.data;
                setStudents(st);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function fetchScore() {
        axios.post('/scores/my-score', {
            id: props.id,
            period: props.period,
        }).then(res => {
            setScore(res.data);
            // console.log(res.data);
            gatherScore(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    function subScoreRow(d) {
        var keys = Object.keys(d);
        return keys.map((v, i) => {
            return <tr key={i}>
                <td>{i + 1}</td>
                <td>{v}</td>
                <td>{((parseInt(d[v]) * subCategories[v] / 100) / score.length).toFixed(2)}</td>
            </tr>
        })
    }

    function subScoreList() {
        var keys = Object.keys(globalScore);
        return keys.map((k, i) => {
            var ky = Object.keys(globalScore[k]);
            var sum = 0;
            ky.forEach(el => {
                sum += parseInt(globalScore[k][el]) * subCategories[el] / 100 / score.length;
            });
            return (
                <tbody key={i}>
                    {props.detail && <tr>
                        <td colSpan="3"><strong>{k}</strong></td>
                    </tr>}
                    {props.detail && subScoreRow(globalScore[k])}
                    {props.detail && <tr>
                        <td colSpan="2"><strong>Total</strong></td>
                        <td>{sum.toFixed(2)}</td>
                    </tr>}
                    {!props.detail && <tr>
                        <td>{i + 1}</td>
                        <td><strong><strong>{k}</strong></strong></td>
                        <td>{sum.toFixed(2)}</td>
                    </tr>}

                </tbody>
            )
        });
    }

    function gatherScore(data) {
        if (data.length === 0) {
            setGlobalScore({});
            return;
        }

        var arr = data.map(d => d["score"]);
        const result = arr.reduce((acc, cur) => {
            return Object.entries(cur).reduce((acc, [key1, val1]) => {
                return Object.entries(val1).reduce((acc, [key2, val2]) => {
                    acc[key1] = acc[key1] || {};
                    acc[key1][key2] = (parseInt(acc[key1][key2]) || 0) + parseInt(val2);
                    return acc;
                }, acc)
            }, acc)
        }, {})

        setGlobalScore(result);
    }

    return (
        <div>
            <div className="w-100 d-flex justify-content-end">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn-sm btn btn-success mb-1 mt-3 noPrint"
                    table={props.name}
                    filename={props.name}
                    sheet="tablexls"
                    buttonText="Export" />
            </div>

            <Table striped bordered hover size="sm" className='mt-2'>
                <thead>
                    <tr>
                        <th colSpan={3}>
                            <div className="d-flex align-items-center">
                                <Image className="mr-2 avatar" src={props.image} thumbnail />
                                <strong><h5>{props.name.toUpperCase()}</h5></strong>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>No</th>
                        <th>Kategori</th>
                        <th>Nilai</th>
                    </tr>
                </thead>
                {subScoreList()}
            </Table>
            <Table id={props.name} striped bordered hover size="sm" className='d-none'>
                <thead>
                    <tr>
                        <th colSpan={3}>
                            {props.name.toUpperCase()}
                        </th>
                    </tr>
                    <tr>
                        <th>No</th>
                        <th>Kategori</th>
                        <th>Nilai</th>
                    </tr>
                </thead>
                {subScoreList()}
            </Table>
        </div>
    )
}

export default AllScore
