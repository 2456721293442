import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import swal from 'sweetalert';

export default class CreatePeriod extends Component {

    constructor(props) {
        super(props)

        // Setting up functions
        this.onChangePeriodName = this.onChangePeriodName.bind(this);
        this.onChangePeriodStatus = this.onChangePeriodStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // Setting up state
        this.state = {
            name: '',
            status: 1
        }
    }

    onChangePeriodName(e) {
        this.setState({ name: e.target.value })
    }

    onChangePeriodStatus(e) {
        this.setState({ status: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        const periodObject = {
            name: this.state.name,
            status: this.state.status,
        };
        console.log(periodObject);
        axios.post('/periods/create-period', periodObject)
            .then(() => {
                swal("Berhasil!", "Kategori berhasil dibuat!", "success").then((value) => {
                    window.location.reload();
                });
            });

        this.setState({ name: '', status: '' })
    }

    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Judul</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangePeriodName} />
                </Form.Group>

                <Form.Group controlId="Status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Status" value={this.state.status} onChange={this.onChangePeriodStatus}>
                        <option value="1">Aktif</option>
                        <option value="0">Draft</option>
                    </Form.Select>
                </Form.Group>


                <Button variant="success" size="sm" block="block" type="submit" className="mt-3 w-100">
                    BUAT
                </Button>
            </Form>
        </div>);
    }
}