import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from "prop-types";

class Login extends Component {

    constructor(props) {
        super(props)

        // Setting up functions
        this.onChangeStudentEmail = this.onChangeStudentEmail.bind(this);
        this.onChangeStudentPassword = this.onChangeStudentPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);

        // Setting up state
        this.state = {
            password: '',
            email: '',
            isForgot: false,
            loading: false
        }
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    onChangeStudentEmail(e) {
        this.setState({ email: e.target.value })
    }

    forgotPassword() {
        this.setState({ isForgot: !this.state.isForgot })
    }

    onChangeStudentPassword(e) {
        this.setState({ password: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()
        this.setState({ loading: true })

        const studentObject = {
            email: this.state.email,
            password: this.state.password,
        };

        if (this.state.isForgot) {
            axios.post('/user/forgot', studentObject)
                .then((data) => {
                    if (data.data.success) {
                        toast.success("Silakan cek inbox / spam folder");
                    } else {
                        toast.error(data.data.message);
                    };
                }).finally(() => {
                    this.setState({ loading: false })
                });
        } else {
            axios.post('/user/login', studentObject)
                .then((data) => {
                    if (data.data.success) {
                        toast.success("Berhasil login");
                        this.props.cookies.set("token", data.data.token, { path: "/" });
                        this.props.cookies.set("role", data.data.role, { path: "/" });
                        this.props.cookies.set("name", data.data.name, { path: "/" });
                        this.props.cookies.set("email", data.data.email, { path: "/" });
                        window.location.href = "/home";
                    } else {
                        toast.error("email / password salah");
                    };
                }).finally(() => {
                    this.setState({ loading: false })
                });
        }



    }

    render() {
        return (
            <div>
                <div className='d-flex justify-content-center h-100 align-items-center pt-3'>

                    <div className="col-8 mb-5 pb-4 z-99">
                        <h3 className="text-center mb-5"><strong>PENILAIAN PEGAWAI PMI KOTA MAGELANG</strong></h3>
                        <Form onSubmit={this.onSubmit} className="mt-5">

                            <Form.Group controlId="Email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control className="bg-half" type="email" value={this.state.email} onChange={this.onChangeStudentEmail} />
                            </Form.Group>

                            {!this.state.isForgot && <Form.Group controlId="Password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control className="bg-half" type="password" value={this.state.password} onChange={this.onChangeStudentPassword} />
                            </Form.Group>}
                            <a role="button" onClick={this.forgotPassword} className="text-end text-blue d-block"><small>{!this.state.isForgot ? "Lupa Password" : "Login"}</small></a>

                            <Button disabled={this.state.loading} variant="danger" size="md" block="block" type="submit" className="mt-4 w-100">
                                {this.state.isForgot ? "RESET PASSWORD" : "LOGIN"}
                            </Button>
                            {this.state.isForgot && <span><small>*password akan dikirim ke email Anda</small></span>}
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(Login);