import { React, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Image from 'react-bootstrap/Image';


function Home() {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const [image, setImage] = useState("");

    useEffect(() => {
        axios.get('/students/edit-student/' + cookies["token"])
            .then(res => {
                setImage(res.data.image);
            })
            .catch((error) => {
                console.log(error);
            })
    })


    return (
        <div className="bg-red">
            <div className="row top-50">
                <div className="col-1 mx-auto mb-3 mt-5">
                    <Image className="mr-2 w-100" src={"/user/image/" + image} thumbnail />
                </div>
                <div className="ml-2 col-12 text-white text-center">
                    <h3>Selamat Datang, {cookies["name"].toUpperCase()}</h3>
                </div>
            </div>

        </div>
    )
}

export default Home
