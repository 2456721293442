import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import swal from 'sweetalert';

export default class CreateStudent extends Component {

    constructor(props) {
        super(props)

        // Setting up functions
        this.onChangeStudentName = this.onChangeStudentName.bind(this);
        this.onChangeStudentEmail = this.onChangeStudentEmail.bind(this);
        this.onChangeStudentRole = this.onChangeStudentRole.bind(this);
        this.onChangeStudentPassword = this.onChangeStudentPassword.bind(this);
        this.onChangeStudentConfirmPassword = this.onChangeStudentConfirmPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // Setting up state
        this.state = {
            name: '',
            password: '',
            password2: '',
            email: '',
            role: '1'
        }
    }

    onChangeStudentName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeStudentEmail(e) {
        this.setState({ email: e.target.value })
    }

    onChangeStudentRole(e) {
        this.setState({ role: e.target.value })
    }

    onChangeStudentPassword(e) {
        this.setState({ password: e.target.value })
    }

    onChangeStudentConfirmPassword(e) {
        this.setState({ password2: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        const studentObject = {
            name: this.state.name,
            email: this.state.email,
            role: this.state.role,
            password: this.state.password,
            password2: this.state.password2
        };
        if (this.state.password !== this.state.password2) {
            swal("Gagal!", "Password harus sama", "error");

            return;
        }
        axios.post('/students/create-student', studentObject)
            .then(() => {
                swal("Berhasil!", "Pegawai berhasil dibuat!", "success").then((value) => {
                    window.location.reload();
                });
            });

        this.setState({ name: '', email: '', role: '', password: '' })
    }

    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Nama</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangeStudentName} />
                </Form.Group>

                <Form.Group controlId="Email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={this.state.email} onChange={this.onChangeStudentEmail} />
                </Form.Group>

                <Form.Group controlId="Password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={this.state.password} onChange={this.onChangeStudentPassword} />
                </Form.Group>

                <Form.Group controlId="Password2">
                    <Form.Label>Konfirmasi Password</Form.Label>
                    <Form.Control type="password" value={this.state.password2} onChange={this.onChangeStudentConfirmPassword} />
                </Form.Group>

                <Form.Group controlId="role">
                    <Form.Label>Role</Form.Label>
                    <Form.Select aria-label="Pilih Role" value={this.state.role} onChange={this.onChangeStudentRole}>
                        <option value="1">Pegawai</option>
                        <option value="2">Supervisor</option>
                    </Form.Select>
                </Form.Group>



                <Button variant="success" size="sm" block="block" type="submit" className="w-100 mt-3">
                    Daftar
                </Button>
            </Form>
        </div>);
    }
}