import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import { FaEyeSlash } from 'react-icons/fa';

export default class PeriodTableRow extends Component {

    constructor(props) {
        super(props);

        this.deletePeriod = this.deletePeriod.bind(this);
    }



    deletePeriod() {
        axios.delete('/periods/delete-period/' + this.props.obj._id)
            .then((res) => {
                swal("Berhasil!", "Kategori telah dihapus!", "success").then((value) => {
                    window.location.reload();
                });
            }).catch((error) => {
                console.log(error)
            })
    }


    render() {
        return (
            <tr>
                <td>{this.props.obj.name} {this.props.obj.status !== 1 && <FaEyeSlash />}
                </td>
                <td>
                    <Link className="edit-link" to={"/edit-period/" + this.props.obj._id}>
                        Edit
                    </Link>
                    <Button onClick={this.deletePeriod} size="sm" variant="danger">Delete</Button>
                </td>
            </tr>
        );
    }
}