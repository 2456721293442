import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ScoreTableRow from './ScoreTableRow';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { FaChevronRight, FaStar, FaArrowLeft, FaUserCircle } from 'react-icons/fa';
import { useHistory } from "react-router-dom";


class StudentScore extends Component {

    constructor(props) {
        super(props)

        this.handleBack = this.handleBack.bind(this);

        this.state = {
            categories: [],
            loading: false,
            students: [],
            periods: [],
            name: "",
            selectedStudent: '',
            selectedStudentId: '',
            selectedPeriod: '',
            selectedPeriodId: '',
            scores: {},
            completion: {},
            sentScore: [],
            childKey: Date.now()
        };
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };



    componentDidMount() {
        this.setState({
            loading: true
        });
        this.getSentScore();
        const { cookies } = this.props;
        var token = cookies.get('token');
        axios.get('/students/edit-student/' + this.props.match.params.id)
            .then(res => {
                this.setState({
                    name: res.data.name
                });
            })
            .catch((error) => {
                console.log(error);
            })
        axios.get('/periods/')
            .then(res => {
                this.setState({
                    periods: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
        axios.get('/categories/')
            .then(res => {
                this.setState({
                    categories: res.data
                });

            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    loading: false
                });
            })
    }


    getScoreData = (val) => {
        var newScores = { ...this.state.scores, ...val };
        this.setState({
            scores: newScores
        })

    }

    getSentScore = () => {
        const { cookies } = this.props;
        var token = cookies.get('token');
        var period = cookies.get('period_id');
        var periodName = cookies.get('period_name');
        this.setState({
            selectedPeriodId: period,
            selectedPeriod: periodName
        });
        axios.post('/scores/sent-score', {
            "sender": this.props.match.params.id,
            "period": period
        }).then(res => {
            this.setState({
                sentScore: res.data
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    getCompletion = (val) => {
        // not used anymore, as selection defaulted to "2" hence always complete
        var newComp = { ...this.state.completion, ...val };
        this.setState({
            completion: newComp
        })


    }


    DataTable() {
        return this.state.categories.map((res, i) => {
            return <ScoreTableRow obj={res} key={i} id={this.state.childKey} sendData={this.getScoreData} sendCompletion={this.getCompletion} />;
        });
    }

    StudentList() {
        return this.state.students.map(std => {
            return (
                <option key={std._id} value={std._id}>{std.name}</option>
            )
        })
    }

    PeriodList() {
        return this.state.periods.map(per => {
            return (
                <option key={per._id} value={per._id}>{per.name}</option>
            )
        })
    }

    categoryScoreList(data) {
        var keys = Object.keys(data);
        return keys.map((k, i) => {
            return (
                <div key={i}>
                    <div><strong>{k}</strong></div>
                    {this.subScoreList(data[k])}
                </div>
            )
        })
    }

    star(val) {
        return [...Array(parseInt(val))].map((k, i) => {
            return (
                <FaStar key={i} color="orange" />
            )
        })
    }

    subScoreList(data) {
        var keys = Object.keys(data);
        return keys.map((k, i) => {
            return (
                <div key={i}><FaChevronRight style={{ marginLeft: "10px", color: "#0dcaf0" }} /> {k} {this.star(data[k])}</div>
            )
        })
    }

    deleteScore = async (id, name) => {
        var result = window.confirm(`Hapus penilaian ${name}?`);
        if (!result) {
            return;
        }
        try {
            await axios.delete(`/scores/delete-score/${id}`);
            toast.success(`Penilaian dihapus`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
            this.getSentScore();
        } catch (error) {
            console.log(error);
            toast.error(`Gagal menghapus`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
            });
        }

    }

    duplicatedScore() {
        var arr = [];
        var duplicated = [];
        this.state.sentScore.forEach(el => {
            if (arr.includes(el.receipient_name)) {
                duplicated.push(el.receipient_name);
            } else {
                arr.push(el.receipient_name);
            }

        });

        return duplicated.map((d, i) => {
            return (
                <li key={i}>
                    {d}
                </li>

            )
        })
    }

    SentScoreList() {
        return this.state.sentScore.map((per, i) => {
            return (
                <div className="border p-2 rounded mb-2 col-md-6 col-12" key={i}>
                    <strong>{i + 1}. {per.receipient_name.toUpperCase()} </strong>
                    <span onClick={() => this.deleteScore(per._id, per.receipient_name.toUpperCase())} style={{ cursor: "pointer", fontSize: "9px" }} className="btn btn-danger btn-sm">Hapus</span>
                    <hr />
                    {this.categoryScoreList(per.score)}
                </div>

            )
        })
    }

    handleBack = (e) => {
        this.props.history.goBack();
        //let history = useHistory();
        //history.goBack();
    }

    handleChange = (e) => {
        if (e.target.name === "period") {
            const { cookies } = this.props;
            cookies.set('period_name', e.target.options[e.target.selectedIndex].text);
            cookies.set('period_id', e.target.value);
            this.setState({
                selectedPeriod: e.target.options[e.target.selectedIndex].text,
                selectedPeriodId: e.target.value,
            })

            if (e.target.value !== "") {
                this.getSentScore();
            }
        }

    }


    render() {
        return (<div className="row fadeIn">
            <div className="d-flex justify-content-center">{this.state.loading && <Spinner animation="border" variant="info" />}</div>
            <div className="p-0 mb-2"><button className="btn btn-primary btn-sm" type="button" onClick={() => this.handleBack()}><FaArrowLeft /></button> <strong>{this.state.name}</strong></div>
            <hr />

            <Form className="col-12 mb-2 p-0">
                <Form.Group controlId="period" className="my-2">
                    <Form.Select required name="period" value={this.state.selectedPeriodId} aria-label="Pilih Periode" onChange={this.handleChange}>
                        <option value="">Pilih Periode</option>
                        {this.PeriodList()}
                    </Form.Select>
                </Form.Group>

            </Form>
            {this.duplicatedScore().length > 0 &&
                <div>
                    <span className="text-danger text-bold">Terdapat duplikasi penilaian:</span>
                    <ul>
                        {this.duplicatedScore()}
                    </ul></div>}
            {this.duplicatedScore().length === 0 &&
                <div className="mb-3"><span className="text-success text-bold">Tidak ada duplikasi</span></div>}

            {this.SentScoreList()}

        </div>);
    }
}

export default withCookies(StudentScore);