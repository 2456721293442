import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';


function ScoreTableRow(props) {

    const [subcategories, setSubcategories] = useState([])
    const [completion, setCompletion] = useState(0)
    const [score, setScore] = useState({ [props.obj.name]: {} })
    const [value, setValue] = useState(props.id);

    useEffect(() => {
        setValue(props.id);
        initialSelect();
        //setCompletion(0);
        //setScore({ [props.obj.name]: {} });
        //props.sendCompletion({ [props.obj.name]: 0 });
    }, [props.id]);


    useEffect(() => {
        getSubCategories(props.obj._id);
        props.sendCompletion({ [props.obj.name]: 0 });

    }, []);

    function initialSelect(data = subcategories) {
        if (data.length === 0) return;
        var newScore = {};
        data.forEach((el, i) => {
            newScore[props.obj.name] = { ...newScore[props.obj.name], [el.name]: "2" };
        });


        setScore(newScore);
        props.sendData(newScore);
        var sc = subcategories.length;
        var checked = Object.keys(newScore[props.obj.name]).length;

        setCompletion(checked / sc);
        props.sendCompletion({ [props.obj.name]: checked / sc });
        setValue(new Date());

    }

    const handleChange = (e) => {
        var name = e.target.name;
        var val = e.target.value;
        var currScore = score[props.obj.name];
        var newScore = { [props.obj.name]: { ...currScore, [name]: val } };
        setScore(newScore);
        props.sendData(newScore);
        var sc = subcategories.length;
        var checked = Object.keys(newScore[props.obj.name]).length;

        setCompletion(checked / sc);
        props.sendCompletion({ [props.obj.name]: checked / sc });


    }

    const getSubCategories = (id) => {
        axios.get(`/categories/subcategories/${id}`)
            .then(res => {
                setSubcategories(res.data);
                initialSelect(res.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const subcategory = () => {
        if (subcategories.length > 0) {
            return subcategories.map((sub, i) => {
                return (
                    <div className="score__container" key={i}>{sub.name}
                        <div key={value}>
                            <Form.Check checked={score[props.obj.name][sub.name] === "1"} name={sub.name} onChange={handleChange} value="1" inline type="radio" label="1" aria-label="1" />
                            <Form.Check checked={score[props.obj.name][sub.name] === "2"} name={sub.name} onChange={handleChange} value="2" inline type="radio" label="2" aria-label="2" />
                            <Form.Check checked={score[props.obj.name][sub.name] === "3"} name={sub.name} onChange={handleChange} value="3" inline type="radio" label="3" aria-label="3" />
                        </div>
                    </div>
                )
            })
        }

        return (
            <span></span>
        )

    }


    return (
        <tr>
            <td>
                <strong>{props.obj.name}</strong>
                {subcategory()}

            </td>
        </tr>
    );

}

export default ScoreTableRow;