import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import swal from 'sweetalert';

export default class CreateSubcategory extends Component {

    constructor(props) {
        super(props)

        // Setting up functions
        this.onChangeSubcategoryName = this.onChangeSubcategoryName.bind(this);
        this.onChangeSubcategoryStatus = this.onChangeSubcategoryStatus.bind(this);
        this.onChangeSubcategoryCategory = this.onChangeSubcategoryCategory.bind(this);
        this.onChangeSubcategoryScore = this.onChangeSubcategoryScore.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // Setting up state
        this.state = {
            name: '',
            category: '',
            status: 1,
            score: '',
            categories: []
        }
    }

    componentDidMount() {
        axios.get('/categories/')
            .then(res => {
                this.setState({
                    categories: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onChangeSubcategoryName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeSubcategoryStatus(e) {
        this.setState({ status: e.target.value })
    }

    onChangeSubcategoryScore(e) {
        this.setState({ score: e.target.value })
    }

    onChangeSubcategoryCategory(e) {
        this.setState({ category: e.target.value })
    }

    categoryOption() {
        return this.state.categories.map(category => {
            return (
                <option key={category["_id"]} value={category["_id"]}>{category["name"]}</option>
            )
        })
    }

    onSubmit(e) {
        e.preventDefault()

        const subcategoryObject = {
            name: this.state.name,
            status: this.state.status,
            category: this.state.category,
            score: this.state.score,
        };
        console.log(subcategoryObject);
        axios.post('/subcategories/create-subcategory', subcategoryObject)
            .then(() => {
                swal("Berhasil!", "Sub Kategori berhasil dibuat!", "success").then((value) => {
                    window.location.reload();
                });
            });

        this.setState({ name: '', status: '' })
    }

    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Judul</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangeSubcategoryName} />
                </Form.Group>

                {/* <Form.Group controlId="Status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Status" value={this.state.status} onChange={this.onChangeSubcategoryStatus}>
                        <option value="1">Aktif</option>
                        <option value="0">Draft</option>
                    </Form.Select>
                </Form.Group> */}

                <Form.Group controlId="Score">
                    <Form.Label>Score</Form.Label>
                    <Form.Control required type="number" value={this.state.score} onChange={this.onChangeSubcategoryScore} />
                </Form.Group>

                <Form.Group controlId="kategori">
                    <Form.Label>Kategori</Form.Label>
                    <Form.Select required aria-label="Status" value={this.state.category} onChange={this.onChangeSubcategoryCategory}>
                        <option value="">Pilih Kategori</option>
                        {this.categoryOption()}
                    </Form.Select>
                </Form.Group>


                <Button variant="success" size="sm" block="block" type="submit" className="w-100 mt-3">
                    BUAT
                </Button>
            </Form>
        </div>);
    }
}