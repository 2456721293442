import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import CategoryTableRow from './CategoryTableRow';


export default class CategoryList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        axios.get('/categories/')
            .then(res => {
                this.setState({
                    categories: res.data
                });

            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    loading: false
                });
            })
    }

    DataTable() {
        return this.state.categories.map((res, i) => {
            return <CategoryTableRow obj={res} key={i} />;
        });
    }

    handleClick = () => {
        this.props.history.push("/create-category");
    }


    render() {
        return (<div className="table-wrapper fadeIn">
            <div className="d-flex justify-content-center">{this.state.loading && <Spinner animation="border" variant="info" />}</div>
            <div className="d-flex justify-content-end mb-2"><Button onClick={this.handleClick} size="sm" variant="success">Tambah</Button></div>
            {!this.state.loading && <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Judul</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.DataTable()}
                </tbody>
            </Table>}

        </div>);
    }
}