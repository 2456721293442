import React, { Component } from "react";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

class Profile extends Component {

    constructor(props) {
        super(props)

        this.onChangeStudentName = this.onChangeStudentName.bind(this);
        this.onChangeStudentEmail = this.onChangeStudentEmail.bind(this);
        this.onChangeStudentRole = this.onChangeStudentRole.bind(this);
        this.onChangeStudentPassword = this.onChangeStudentPassword.bind(this);
        this.onChangeStudentConfirmPassword = this.onChangeStudentConfirmPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // State
        this.state = {
            name: '',
            email: '',
            image: '',
            password: '',
            password2: '',
            image: '',
            role: '',
            loading: false,
            state: new Date()
        }
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    componentDidMount() {
        const { cookies } = this.props;
        var token = cookies.get('token');
        axios.get('/students/edit-student/' + token)
            .then(res => {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    image: res.data.image,
                    role: res.data.role
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onChangeStudentName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeStudentEmail(e) {
        this.setState({ email: e.target.value })
    }

    onChangeStudentRole(e) {
        this.setState({ role: e.target.value })
    }

    onChangeStudentPassword(e) {
        this.setState({ password: e.target.value })
    }

    onChangeStudentConfirmPassword(e) {
        this.setState({ password2: e.target.value })
    }

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    handleFileUpload = async (e) => {
        this.setState({ loading: true })
        const file = e.target.files[0];
        var form = new FormData();
        form.append('image', file);
        axios.post('/user/uploadImage', form).then((response) => {
            this.setState({ image: response.data.filename })
        }).catch((error) => {
            toast.error(`Upload image gagal`);
        }).finally(() => {
            this.setState({ loading: false })
        });
    };

    onSubmit(e) {
        e.preventDefault();
        const { cookies } = this.props;
        var token = cookies.get('token');

        let studentObject = {};

        if (this.state.password !== '') {
            studentObject = {
                name: this.state.name,
                email: this.state.email,
                image: this.state.image,
                password: this.state.password
            };
        } else {
            studentObject = {
                name: this.state.name,
                email: this.state.email,
                image: this.state.image
            };
        }


        if (this.state.password !== this.state.password2) {
            toast.error(`Password harus sama`);
            return;
        }

        axios.put('/students/update-student/' + token, studentObject)
            .then((res) => {
                toast.success(`Profile berhasil diupdate`);
                window.location.reload();
            }).catch((error) => {
                console.log(error)
            })

        // Redirect to Student List 

    }


    render() {
        return (<div className="form-wrapper">

            <div className="row d-flex align-items-center mb-2">
                <div className="col-2">
                    <Image className="mr-2 w-100" src={"/user/image/" + this.state.image} thumbnail />
                </div>
                <div className="ml-2 col-8">
                    <strong><h5>{this.state.name}</h5></strong>
                </div>
            </div>
            <hr />
            <Form className="mt-2 mb-5" onSubmit={this.onSubmit}>
                <div className="row mb-2">
                    <Form.Group className="col-6" controlId="Name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={this.state.name} onChange={this.onChangeStudentName} />
                    </Form.Group>
                    <Form.Group className="col-6" controlId="Email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={this.state.email} onChange={this.onChangeStudentEmail} />
                    </Form.Group>
                </div>

                <Form.Group controlId="formFile">
                    <Form.Label>Foto Profil</Form.Label>
                    <Form.Control onChange={(e) => this.handleFileUpload(e)} type="file" />
                </Form.Group>
                <div className="row mt-2">
                    <div className="col-12 text-info"><small>Isi field berikut untuk mengganti password:</small></div>
                    <Form.Group className="col-6" controlId="Password">
                        <Form.Label>Password Baru</Form.Label>
                        <Form.Control type="password" value={this.state.password} onChange={this.onChangeStudentPassword} />
                    </Form.Group>

                    <Form.Group className="col-6" controlId="Password2">
                        <Form.Label>Konfirmasi Password</Form.Label>
                        <Form.Control type="password" value={this.state.password2} onChange={this.onChangeStudentConfirmPassword} />
                    </Form.Group>
                </div>



                <Button disabled={this.state.loading} variant="success" size="sm" type="submit" className="w-100 mt-3">
                    Simpan
                </Button>
            </Form>
        </div>);
    }
}

export default withCookies(Profile);