import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import swal from 'sweetalert';

export default class CreateCategory extends Component {

    constructor(props) {
        super(props)

        // Setting up functions
        this.onChangeCategoryName = this.onChangeCategoryName.bind(this);
        this.onChangeCategoryStatus = this.onChangeCategoryStatus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // Setting up state
        this.state = {
            name: '',
            status: 1
        }
    }

    onChangeCategoryName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeCategoryStatus(e) {
        this.setState({ status: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        const categoryObject = {
            name: this.state.name,
            status: this.state.status,
        };
        console.log(categoryObject);
        axios.post('/categories/create-category', categoryObject)
            .then(() => {
                swal("Berhasil!", "Kategori berhasil dibuat!", "success").then((value) => {
                    window.location.reload();
                });
            });

        this.setState({ name: '', status: '' })
    }

    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Judul</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangeCategoryName} />
                </Form.Group>

                {/* <Form.Group controlId="Status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Status" value={this.state.status} onChange={this.onChangeCategoryStatus}>
                        <option value="1">Aktif</option>
                        <option value="0">Draft</option>
                    </Form.Select>
                </Form.Group> */}


                <Button variant="success" size="sm" block="block" type="submit" className="w-100 mt-3">
                    BUAT
                </Button>
            </Form>
        </div>);
    }
}