import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

export default class EditStudent extends Component {

    constructor(props) {
        super(props)

        this.onChangeStudentName = this.onChangeStudentName.bind(this);
        this.onChangeStudentEmail = this.onChangeStudentEmail.bind(this);
        this.onChangeStudentRole = this.onChangeStudentRole.bind(this);
        this.onChangeStudentImage = this.onChangeStudentImage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // State
        this.state = {
            name: '',
            email: '',
            role: '',
            image: ''
        }
    }

    componentDidMount() {
        axios.get('/students/edit-student/' + this.props.match.params.id)
            .then(res => {
                this.setState({
                    name: res.data.name,
                    email: res.data.email,
                    role: res.data.role,
                    image: res.data.image
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onChangeStudentName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeStudentEmail(e) {
        this.setState({ email: e.target.value })
    }

    onChangeStudentImage(e) {
        this.setState({ image: e.target.value })
    }

    onChangeStudentRole(e) {
        this.setState({ role: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault()

        const studentObject = {
            name: this.state.name,
            email: this.state.email,
            role: this.state.role,
            image: this.state.image
        };

        axios.put('/students/update-student/' + this.props.match.params.id, studentObject)
            .then((res) => {
                console.log(res.data)
                console.log('Student successfully updated')
                this.props.history.push('/student-list')
            }).catch((error) => {
                console.log(error)
            })

        // Redirect to Student List 

    }


    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangeStudentName} />
                </Form.Group>

                <Form.Group controlId="Email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={this.state.email} onChange={this.onChangeStudentEmail} />
                </Form.Group>

                <Form.Group controlId="Name">
                    <Form.Label>Role</Form.Label>
                    <Form.Select aria-label="Pilih Role" value={this.state.role} onChange={this.onChangeStudentRole}>
                        <option value="1">Pegawai</option>
                        <option value="2">Supervisor</option>
                    </Form.Select>
                </Form.Group>

                <Button variant="success" size="sm" block="block" type="submit" className="w-100 mt-3">
                    Update Pegawai
                </Button>
            </Form>
        </div>);
    }
}