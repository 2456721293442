import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';

export default class StudentTableRow extends Component {

    constructor(props) {
        super(props);
        this.deleteStudent = this.deleteStudent.bind(this);
    }

    deleteStudent() {
        axios.delete('/students/delete-student/' + this.props.obj._id)
            .then((res) => {
                swal("Berhasil!", "Pegawai telah dihapus!", "success").then((value) => {
                    window.location.reload();
                });
            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <tr>
                <td>{this.props.obj.name}</td>
                <td>{this.props.obj.email}</td>
                <td>{this.props.obj.role === 1 ? "Karyawan" : this.props.obj.role === 2 ? "Supervisor" : "Manager"}</td>
                <td>
                    <Link className="edit-link" to={"/edit-student/" + this.props.obj._id}>
                        Edit
                    </Link>
                    <Button className="" onClick={this.deleteStudent} size="sm" variant="danger">Delete</Button>
                    <Link className="mx-2 btn btn-warning btn-sm" to={"/sent-score/" + this.props.obj._id}>
                        Nilai
                    </Link>
                </td>
            </tr>
        );
    }
}