import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <ToastContainer />
      <App />
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("root")
)


serviceWorker.unregister()