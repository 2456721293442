import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

export default class EditSubcategory extends Component {

    constructor(props) {
        super(props)

        this.onChangeSubcategoryName = this.onChangeSubcategoryName.bind(this);
        this.onChangeSubcategoryStatus = this.onChangeSubcategoryStatus.bind(this);
        this.onChangeSubcategoryCategory = this.onChangeSubcategoryCategory.bind(this);
        this.onChangeSubcategoryScore = this.onChangeSubcategoryScore.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        // State
        this.state = {
            name: '',
            status: '',
            score: '',
            category: '',
            categories: [],
        }
    }

    componentDidMount() {
        axios.get('/subcategories/edit-subcategory/' + this.props.match.params.id)
            .then(res => {
                this.setState({
                    name: res.data.name,
                    status: res.data.status,
                    category: res.data.category,
                    score: res.data.score,
                });
            })
            .catch((error) => {
                console.log(error);
            })

        axios.get('/categories/')
            .then(res => {
                this.setState({
                    categories: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    onChangeSubcategoryName(e) {
        this.setState({ name: e.target.value })
    }

    onChangeSubcategoryStatus(e) {
        this.setState({ status: e.target.value })
    }

    onChangeSubcategoryCategory(e) {
        this.setState({ category: e.target.value })
    }

    onChangeSubcategoryScore(e) {
        this.setState({ score: e.target.value })
    }

    categoryOption() {
        return this.state.categories.map(category => {
            return (
                <option key={category["_id"]} value={category["_id"]}>{category["name"]}</option>
            )
        })
    }

    onSubmit(e) {
        e.preventDefault()

        const subcategoryObject = {
            name: this.state.name,
            status: this.state.status,
            category: this.state.category,
            score: this.state.score
        };

        axios.put('/subcategories/update-subcategory/' + this.props.match.params.id, subcategoryObject)
            .then((res) => {
                this.props.history.push('/subcategory-list')
                console.log('Subcategory successfully updated')
            }).catch((error) => {
                console.log(error)
            })

        // Redirect to Subcategory List 

    }


    render() {
        return (<div className="form-wrapper">
            <Form onSubmit={this.onSubmit}>
                <Form.Group controlId="Name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.onChangeSubcategoryName} />
                </Form.Group>

                <Form.Group controlId="Score">
                    <Form.Label>Skor</Form.Label>
                    <Form.Control type="number" value={this.state.score} onChange={this.onChangeSubcategoryScore} />
                </Form.Group>

                {/* <Form.Group controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Pilih Status" value={this.state.status} onChange={this.onChangeSubcategoryStatus}>
                        <option value="1">Aktif</option>
                        <option value="0">Draft</option>
                    </Form.Select>
                </Form.Group> */}

                <Form.Group controlId="kategori">
                    <Form.Label>Kategori</Form.Label>
                    <Form.Select required aria-label="Status" value={this.state.category} onChange={this.onChangeSubcategoryCategory}>
                        <option value="">Pilih Kategori</option>
                        {this.categoryOption()}
                    </Form.Select>
                </Form.Group>

                <Button className="mt-3 w-100" variant="success" size="sm" block="block" type="submit">
                    Update Sub Kategori
                </Button>
            </Form>
        </div>);
    }
}