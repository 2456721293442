import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';

export default class SubcategoryTableRow extends Component {

    constructor(props) {
        super(props);
        this.deleteSubcategory = this.deleteSubcategory.bind(this);
    }

    categoryName() {
        let catObj = {};
        this.props.cats.forEach(cat => {
            catObj[cat["_id"]] = cat["name"];
        });

        return catObj;
    }

    deleteSubcategory() {
        axios.delete('/subcategories/delete-subcategory/' + this.props.obj._id)
            .then((res) => {
                swal("Berhasil!", "Sub Kategori telah dihapus!", "success").then((value) => {
                    window.location.reload();
                });
            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <tr>
                <td>{this.props.obj.name}</td>
                <td>{this.props.obj.score}</td>
                <td>{this.categoryName()[this.props.obj.category]}</td>
                <td>
                    <Link className="edit-link" to={"/edit-subcategory/" + this.props.obj._id}>
                        Edit
                    </Link>
                    <Button onClick={this.deleteSubcategory} size="sm" variant="danger">Delete</Button>
                </td>
            </tr>
        );
    }
}